import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canDelete)?_c(VBtn,_vm._g({attrs:{"icon":"","elevation":"0","color":"main","disabled":_vm.loading,"loading":_vm.loading}},on),[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()]}}]),model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,{staticClass:"headline grey lighten-2",staticStyle:{"word-break":"break-word"}},[_c('span',[_vm._v(_vm._s(_vm.$t("sessionsPage.deleteSession.youSureDelete")))])]),_c(VCardText,{staticClass:"mt-6"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.deleteSession.deletionInfo"))+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.confirmDeleteDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("sessionsPage.deleteSession.cancelSessDel")))])]),_c(VBtn,{attrs:{"elevation":"0","color":"prezpRed other--text","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete($event)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("sessionsPage.deleteSession.confirmSessDel")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }